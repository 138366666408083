import * as React from "react"
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews"
import { previewLinkResolver } from "../preview-linkresolver"

const PreviewPage = () => {
  return (
    <div>
      <p>Loading preview...</p>
    </div>
  )
}

export default withPrismicPreviewResolver(PreviewPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver: previewLinkResolver
  },
])